<template>
  <div class="impressum row justify-center">
          <!-- eslint-disable max-len -->
    <div style="max-width: 1024px" class="q-pa-md">
      <div class="row justify-center q-pa-md">
          <div class="text-h3">Impressum</div>
      </div>
      <div class="text-body1">
        <div class="text-h4">Angaben gem&auml;&szlig; &sect; 5 TMG</div>
        <p>Jessica Scheuern<br />
        K&uuml;nstlerkinder<br />
        Erbsengasse 17<br />
        61169 Friedberg</p>

        <div class="text-h4">Kontakt</div>
        <p>
        E-Mail: kuenstlerkinder@web.de</p>

        <div class="text-h4">Umsatzsteuer-ID</div>
        <p>Umsatzsteuer-Identifikationsnummer gem&auml;&szlig; &sect; 27 a Umsatzsteuergesetz:<br />
        16 865 0382 0</p>
        <div class="text-h4">Angaben zur Berufs&shy;haftpflicht&shy;versicherung</div>
        <p><strong>Name und Sitz des Versicherers:</strong><br />
        Rhion Versicherung AG<br />
        RheinLandplatz<br />
        41460 Neuss</p>
        <p><strong>Geltungsraum der Versicherung:</strong><br />Deutschland</p>

        <div class="text-h4">EU-Streitschlichtung</div>
        <p>Die Europ&auml;ische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit: <a href="https://ec.europa.eu/consumers/odr/" target="_blank" rel="noopener noreferrer">https://ec.europa.eu/consumers/odr/</a>.<br /> Unsere E-Mail-Adresse finden Sie oben im Impressum.</p>

        <div class="text-h4">Verbraucher&shy;streit&shy;beilegung/Universal&shy;schlichtungs&shy;stelle</div>
        <p>Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.</p>

        <div class="text-h4">Urheberrecht</div>
        <p>Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem deutschen Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser Seite sind nur für den privaten, nicht kommerziellen Gebrauch gestattet.

        Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte Dritter beachtet. Insbesondere werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine Urheberrechtsverletzung aufmerksam werden, bitten wir um einen entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen.</p>

        <p>Quelle: <a href="https://www.e-recht24.de">https://www.e-recht24.de</a></p>
      </div>
    </div>
  </div>
</template>
