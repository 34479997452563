<!-- eslint-disable max-len */ -->
<template>
          <q-dialog
              v-model="meetingUpdateState"
            >
            <div class="row justify-center" style="background:#FBFFEB">
              <div class="col" style="width: 650px">
              <p class="text-h5" align="center">Termin updaten</p>
              <q-input outlined :dense="true" placeholder="Titel"
              id="title" type="text" v-model="meetingUpdateData.title" />
              <q-input outlined :dense="true" placeholder="Termin"
              type="datetime-local" v-model="meetingUpdateData.date" hint="Termin"/>
              <q-input outlined :dense="true" placeholder="TerminEnde"
              type="datetime-local" v-model="meetingUpdateData.date_end" hint="Terminende"/>
              <q-input outlined :dense="true" placeholder="Preis"
              type="text" v-model="meetingUpdateData.price" />
              <q-input outlined :dense="true" placeholder="Beschreibung"
              type="textarea" v-model="meetingUpdateData.description" rows="5" cols="25" />
              <q-btn v-if="!meetingUpdateData.publish"
              label="Termin versteckt" style="width:100%"
              v-on:click="meetingUpdateData.publish = !meetingUpdateData.publish"/>
              <q-btn v-if="meetingUpdateData.publish"
              label="Termin veröffentlicht" style="width:100%"
              v-on:click="meetingUpdateData.publish = !meetingUpdateData.publish"/>
              <q-btn v-if="meetingUpdateData.registration_close"
              label="Buchung geschlossen" style="width:100%"
              v-on:click="meetingUpdateData.registration_close = !meetingUpdateData.registration_close"/>
              <q-btn v-if="!meetingUpdateData.registration_close"
              label="Buchung offen" style="width:100%"
              v-on:click="meetingUpdateData.registration_close = !meetingUpdateData.registration_close"/>

              <q-btn label="update" type="submit" color="primary"

              registration_close
              v-on:click="meetingUpdate(meetingUpdateData)" />
              </div>
            </div>
        </q-dialog>
    <q-table
      title="Workshops"
      :rows="meetings[0]?.title ? meetings : []"
      :columns="columns"
      row-key="name"
      :wrap-cells=true
      dense
      :table-header-style="{'text-stroke':'thin'}"
      no-data-label="keine Daten verfügbar"
      :pagination="{
          sortBy: 'date', //string column name
          descending: true //boolean
      } "
      :loading="loading"
      :rows-per-page-options="[10, 15, 20, 30, 0]"
    >
    <template v-slot:body="props">
      <q-tr :props="props">
        <q-td key="publish" :props="props">
          {{ props.row.publish ? 'Veröffentlicht' : 'Versteckt' }}
        </q-td>
        <q-td key="title" :props="props">
          {{ props.row.title }}
        </q-td>
        <q-td key="description" :props="props">
          {{ props.row.description }}
        </q-td>
        <q-td key="price" :props="props">
          {{ props.row.price }}€
        </q-td>
        <q-td key="date" :props="props">
          {{ fGetDateHumanDay(props.row.date) + ' ' + fGetDateHumanTime(props.row.date) }}
        </q-td>
        <q-td key="date_end" :props="props">
          {{ fGetDateHumanDay(props.row.date_end) + ' ' + fGetDateHumanTime(props.row.date_end) }}
        </q-td>

        <q-menu
        context-menu
        touch-position
      >
        <!-- righclick table menu -->
        <q-list dense style="min-width: 100px">
          <q-item clickable v-close-popup style="background: #007c97">
            <q-item-section @click="meetingUpdateView(props.row)">Edit</q-item-section>
          </q-item>
          <q-item clickable v-close-popup style="background: #ED005B">
            <q-item-section @click="fDeleteMeeting(props.row.id)">Delete</q-item-section>
          </q-item>
          </q-list>
        </q-menu>
      </q-tr>

      </template>
      </q-table>
</template>

<script>
import { API } from 'aws-amplify';
import { mapState } from 'vuex';
import moment from 'moment';
import { onCreateMeeting } from '@/graphql/subscriptions';
import HelperMeetings from '@/libs/HelperMeetings';

export default {
  name: 'ListMeetings',
  data() {
    return {
      isMobile: false,
      meetingUpdateState: false,
      meetingUpdateData: {},

      // id: item.id,
      //   title: item.title,
      //   price: item.price,
      //   date: item.date,
      //   date_end: item.date_end,
      //   publish: !item.publish,
      //   description: item.description,
      //   registration_close: item.registration_close,

      columns: [
        {
          name: 'publish', align: 'left', label: 'Published', field: 'publish', sortable: true,
        },
        {
          name: 'title', align: 'center', label: 'Titel', field: 'title', sortable: true,
        },

        { name: 'description', label: 'Beschreibung', field: 'description' },
        {
          name: 'price', label: 'Preis', field: 'price', sortable: false,
        },
        {
          name: 'date',
          label: 'Startdatum',
          field: 'date',
          sortable: true,
        },
        {
          name: 'date_end',
          label: 'Enddatum',
          field: 'date_end',
          sortable: true,
        },
      ],
    };
  },
  setup() {
    const {
      probs,
      fOrderMeetings,
      fCreateMeeting,
      fGetMeetings,
      fDeleteMeeting,
      fUpdateMeeting,
      fGetDateHuman,
      fGetDateHumanDay,
      fGetDateHumanTime,
    } = HelperMeetings();
    return {
      probs,
      fOrderMeetings,
      fCreateMeeting,
      fGetMeetings,
      fDeleteMeeting,
      fUpdateMeeting,
      fGetDateHuman,
      fGetDateHumanDay,
      fGetDateHumanTime,
    };
  },
  created() {
    this.subscribe();
    this.setViewport();
    this.fGetMeetings();
  },
  computed: {
    ...mapState(['meetings', 'auth', 'loading']),
  },
  methods: {
    subscribe() {
      API.graphql({ query: onCreateMeeting, authMode: 'API_KEY' })
        .subscribe({
          next: (eventData) => {
            const meeting = eventData.value.data.onCreateMeeting;
            if (this.probs.meetings.some((item) => item.date === meeting.date)) return;
            // remove duplications
            this.probs.meetings = [...this.meetings, meeting];
            console.log('subscribeOnMeeting');
          },
        });
    },
    // eslint-disable-next-line camelcase
    meetingUpdateView(item) {
      // .id, item.title, item.price, item.date, item.date_end, item.description, item.publish, item.registration_close
      console.log(item.id, item.date, item.date_end, item.registration_close);
      // const date = moment.utc(idate).add(1, 'hour').format('YYYY-MM-DDTHH:mm');
      const date = moment(item.date).format('YYYY-MM-DDTHH:mm');
      // eslint-disable-next-line camelcase
      // const date_end = moment.utc(idate_end).add(1, 'hour').format('YYYY-MM-DDTHH:mm');
      // eslint-disable-next-line camelcase
      const date_end = moment(item.date_end).format('YYYY-MM-DDTHH:mm');

      console.log(item.date, item.date_end);
      this.meetingUpdateData = {
        id: item.id,
        title: item.title,
        price: item.price,
        date,
        date_end,
        publish: item.publish,
        description: item.description,
        registration_close: item.registration_close,
      };
      this.meetingUpdateState = true;
      // this.meetingUpdate(this.meetingUpdateData);
    },
    togglePublishState(item) {
      this.meetingUpdateData = {
        id: item.id,
        title: item.title,
        price: item.price,
        date: item.date,
        date_end: item.date_end,
        publish: !item.publish,
        description: item.description,
        registration_close: item.registration_close,
      };
      this.meetingUpdate(this.meetingUpdateData);
    },
    meetingUpdate(meetingUpdateData) {
      this.fUpdateMeeting(meetingUpdateData).then((val) => {
        console.log(val);
        this.meetingUpdateState = false;
      });
    },
    setViewport() {
      if (document.documentElement.clientWidth >= 960) {
        this.isMobile = false;
        return;
      }
      this.isMobile = true;
    },
    onResize(event) {
      this.setViewport();
      console.log('window has been resized', event);
    },
  },
  mounted() {
  // Register an event listener when the Vue component is ready
    window.addEventListener('resize', this.onResize);
  },
  beforeUnmount() {
  // Unregister the event listener before destroying this Vue instance
    window.removeEventListener('resize', this.onResize);
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
