<template>
    <div class="administration" >
        <div style="background:#c2dd5d; padding: 1rem">
            <p class="text-center text-h5">Administration</p>
        </div>
        <div class="administration" style="background:#FBFFEB">
          <Admin></Admin>
        </div>
    </div>
</template>

<script>
// @ is an alias to /src
import { mapState } from 'vuex';
import Admin from '@/components/Administration.vue';
// import HelloWorld from '@/components/HelloWorld.vue';

export default {
  name: 'Home',
  components: {
    Admin,
  },
  data() {
    return {
    };
  },
  created() {
  },
  computed: {
    ...mapState(['auth']),
  },
  methods: {
    // myUploader(event) {
    //   event.files.forEach((element) => {
    //   });

    // event.files == files to upload
    // }

  },
};
</script>
