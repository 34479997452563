<template>
  <div class="about row justify-center">
          <!-- eslint-disable max-len -->
    <div style="max-width: 1024px" class="q-pa-md">
      <div class="row justify-center q-pa-md">
          <div class="text-h3">Über mich</div>
      </div>
      <div class="row">
        <div class="col-12 col-md-6 q-pa-md">
          <div class="text-body1">Mein Name ist Jessica Scheuern, <br><br>
        ich bin glücklich verheiratet und habe zwei wundervolle Kinder.
        Nach meinem Abschluss zur Erzieherin habe ich an zahlreichen Fortbildungen zum Thema Kunst, Theater und Tanz teilgenommen.
        Zudem absolvierte ich eine Weiterbildung zur Entspannungspädagogin und Klangenergetikerin.
        Nebenbei schreibe und illustriere ich leidenschaftlich gerne kreative und fantasievolle Kinderbücher.
        In den letzten Jahren habe ich erfolgreich selbst geschriebene Musicals mit Kindern aufgeführt.
        Die Arbeit mit Kindern bereitet mir sehr viel Vergnügen!
      </div><br>
      <div class="text-body1">
        Zum Abschluss noch ein Zitat aus meinen Kinderbüchern:
        "Manchmal macht ein wenig Fantasie unser Leben einfach einfacher"
      </div><br>
      <div class="text-body1">
        Jessica Scheuern<br><br>
      </div>
        </div>
        <div class="col-12 col-md-6 self-center">
            <q-img class="shadow-7" :src="require('@/assets/portrait.jpg')"
            style="border-radius:15px; transform: rotate(2deg)"/>
        </div>
      </div>
    </div>
  </div>
</template>
